<template>
  <component
    :is="type"
    v-if="text"
    title-component
    class="
      font-semibold
      prose
      tracking-wider
      prose-headings:mb-0 prose-headings:text-unset prose-headings:font-semibold
      prose-strong:text-brandRed prose-strong:font-semibold
      prose-headings:text-[unset] prose-headings:leading-none
    "
    :class="[
      colorItalic,
      colorBold,
      size,
      sizeDesktop,
      color,
      desktopAlign,
      align,
    ]"
    v-html="text.html"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    text: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'h2',
    },
    size: {
      type: String,
      required: false,
      default: 'text-base',
    },
    sizeDesktop: {
      type: String,
      required: false,
      default: 'text-base',
    },
    variant: {
      type: String,
      default: 'default',
    },
    color: {
      type: String,
      default: undefined,
    },
    colorItalic: {
      type: String,
      default: undefined,
    },
    colorBold: {
      type: String,
      default: undefined,
    },
    desktopAlign: {
      type: String,
      required: false,
      default: 'text-left',
    },
    align: {
      type: String,
      required: false,
      default: 'text-left',
    },
  },
}
</script>
